<template>
  <div>
    <v-btn
      small
      fab
      v-model="filename"
      color="#f8f8f8"
      @click.native="onFocus"
      :disabled="disabled"
    >
      <v-icon
        dark
        color="primary"
        large
      >attach_file</v-icon>
    </v-btn>
    <input
      type="file"
      :accept="accept"
      :multiple="false"
      :disabled="disabled"
      ref="fileInput"
      @change="onFileChange"
    >

  </div>
</template>
<script>
export default {
  name: 'fileUpload',
  props: {
    value: {
      type: [Array, String]
    },
    accept: {
      type: String,
      default: "*"
    },
    label: {
      type: String,
      default: "Please choose..."
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean, // not yet possible because of data
      default: false
    }
  },
  data() {
    return {
      filename: ""
    };
  },
  watch: {
    value(v) {
      this.filename = v;
    }
  },
  mounted() {
    this.filename = this.value;
  },

  methods: {
    getFormData(files) {
      const data = new FormData();
      [...files].forEach(file => {
        data.append("data", file, file.name); // currently only one file at a time
      });
      return data;
    },
    onFocus() {
      if (!this.disabled) {
        this.$refs.fileInput.click();
      }
    },
    onFileChange($event) {
      const files = $event.target.files || $event.dataTransfer.files;
      const form = this.getFormData(files);
      if (files) {
        if (files.length > 0) {
          this.filename = [...files].map(file => file.name).join(", ");
        } else {
          this.filename = null;
        }
      } else {
        this.filename = $event.target.value.split("\\").pop();
      }

      this.filename = $event.target.files[0].name;
      this.$emit("change", this.filename);
      var vm = this;
      this.$emit("formData", form, vm.filename, $event.target.files[0]);
    }
  }
};
</script>
<style scoped>
input[type="file"] {
  position: absolute;
  left: -99999px;
}
</style>
