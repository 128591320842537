<template>
   <v-container fluid class="down-top-padding">
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-col>
    <image-upload
              :image="userPicture"
              v-on:uploaded="fileUploaded"
            />
    </v-col>
  </v-container>
</template>

<script>
import imageUpload from "../../components/ImageUpload"
import { mapGetters } from "vuex";
export default {
  name: "profile",
  components: {
    imageUpload
  },
  data: () => ({
    page: {
      title: "profile",
      icon: "mdi-account",
    },
    elementVisible: false,
    breadcrumbs: [
      {
        text: "profile",
        disabled: true,
      },
    ],
    loading: false,
  }),
  computed: {
    ...mapGetters("users",{
      userPicture: 'userPic'
    })
  },
  methods: {
    async fileUploaded(uploadedImage) {
    },
  }
};
</script>
