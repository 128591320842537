<template>
  <v-hover>
    <v-card
      flat
      class="pa-0 ma-0"
      slot-scope="{ hover }"
    >
      <v-card-text class="pb-0 ma-0">
        <template v-if="!imageUrl || imageUrl.length === 0">
          <div
            v-if="fileLoading === true"
            class="d-flex grey lighten-4 pa-3"
            style="height: 100%;"
          >
            <v-progress-circular
              :rotate="-90"
              :size="100"
              :width="15"
              :value="percentage"
              color="primary"
            >
              {{ percentage }}
            </v-progress-circular>
          </div>
          <div v-else>
            <form
              enctype="multipart/form-data"
              novalidate
            >
              <div class="dropbox">
                <input
                  type="file"
                  :multiple="multiple"
                  name="photos"
                  :disabled="fileLoading"
                  @change="addImage($event.target.files)"
                  accept="image/*"
                  class="input-file"
                >
                <p>
                  Drag Files or Click to Browse
                </p>
              </div>
            </form>
          </div>
        </template>
        <template v-else>
          <v-img :src="imageUrl">
            <v-expand-transition>
              <v-flex
                d-flex
                class="pa-0 ma-0"
              >
                <div
                  v-if="fileLoading === true"
                  class="d-flex transition-fast-in-fast-out grey lighten-4 v-card--reveal"
                  style="height: 100%;"
                >
                  <v-progress-circular
                    :rotate="-90"
                    :size="100"
                    :width="15"
                    :value="percentage"
                    color="primary"
                  >
                    {{ percentage }}
                  </v-progress-circular>
                </div>
              </v-flex>
            </v-expand-transition>
            <v-expand-transition>
              <div
                v-if="hover && fileLoading === false"
                class="d-flex transition-fast-in-fast-out v-card--reveal"
                style="height: 100%;"
              >
                <v-flex>
                  <v-layout
                    row
                    align-center
                    justify-center
                  >
                    <file-upload
                      :multiple="true"
                      :value="fileName"
                      v-on:formData="updateImage"
                      :disabled="false"
                    />
                  </v-layout>
                </v-flex>

              </div>
            </v-expand-transition>
            <v-layout
              slot="placeholder"
              fill-height
              align-center
              justify-center
            >
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-layout>
          </v-img>
        </template>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import FileUpload from "./FileUpload";

export default {
  name: "image-upload",
  components: {
    FileUpload
  },
  props: {
    image: Object,
    multiple: Boolean
  },
  data() {
    return {
      hover: false,
      fileName: "",
      fileLoading: false,
      imageUrl: ""
    };
  },
  computed: {
    percentage() {
      return this.$store.getters.progress;
    }
  },
  watch: {
    image(newImage) {
      if (newImage) {
        this.imageUrl = newImage.url;
      }
    }
  },
  methods: {
    async updateImage(value1, value2, value3) {
      this.formData = new FormData();
      this.formData.append("File", value3);
      this.formData.append("ImageId", this.image.imageId);

      this.fileLoading = true;
    //   const response = await this.$store
    //     .dispatch("updateImagePicture", this.formData)
    //     .finally(() => (this.fileLoading = false));

    //   if (response) {
    //     this.imageUrl = response.url;
    //   }
    },
    async addImage(fileList) {
      const formData = new FormData();
      formData.append("File", fileList[0]);
      formData.append("ImageId", 0);

    //   this.fileLoading = true;

    //   const response = await this.$store
    //     .dispatch("uploadImage", formData)
    //     .finally(() => (this.fileLoading = false));

    //   if (response) {
    //     this.imageUrl = response.url;
    //     this.$emit("uploaded", response);
    //   }
    },
    getFormData(files) {
      const data = new FormData();
      [...files].forEach(file => {
        data.append("data", file, file.name);
      });
      return data;
    },
    onFocus() {
      if (!this.disabled) {
        this.$refs.fileInput.click();
      }
    },
    onFileChange($event) {
      const files = $event.target.files || $event.dataTransfer.files;
      const form = this.getFormData(files);
      if (files) {
        if (files.length > 0) {
          this.filename = [...files].map(file => file.name).join(", ");
        } else {
          this.filename = null;
        }
      } else {
        this.filename = $event.target.value.split("\\").pop();
      }
      this.$emit("input", this.filename);
      this.$emit("formData", form);
    }
  },
  mounted() {
    if (this.image) {
      this.imageUrl = this.image.url;
    }
  }
};
</script>

<style>
.v-card--reveal {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.9;
  position: absolute;
  width: 100%;
}

.dropbox {
  background-color: lightblue;
  color: dimgray;
  outline: 1px #c0c0c0 dashed;
  padding: 10px 10px;
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightgray;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
